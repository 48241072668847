// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
// sweet alert
@import 'sweetalert2/src/sweetalert2.scss';
// flag-icons
@import 'flag-icons/sass/flag-icons.scss';
// devextreme -> base theme
@import '../../node_modules/devextreme/dist/css/dx.light.css';
//@import '../../node_modules/devextreme/dist/css/dx.greenmist.css';
//@import '../../node_modules/devextreme/dist/css/dx.material.blue.light.css';
// devextreme -> custom theme
//@import '../../doc/themes/kvuszkar-theme/20220314_noextresources/dx.material.kvuszkar-theme.css';
.dx-widget,
body {
    font-size: $default-font-size !important;
}

// .dx-overlay-wrapper {
//     font-size: $default-font-size !important;
// }
.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.blue {
    color: $blue;
}

.indigo {
    color: $indigo;
}

.purple {
    color: $purple;
}

.pink {
    color: $pink;
}

.red {
    color: $red;
}

.orange {
    color: $orange;
}

.yellow {
    color: $yellow;
}

.green {
    color: $green;
}

.teal {
    color: $teal;
}

.cyan {
    color: $cyan;
}

// .content-header{
//   padding: 10px 0.5rem !important;
// }
[role="button"] {
    cursor: pointer !important;
    font-size: $default-font-size;
    height: 40px;
    color: black !important;
    padding: 5px;
}

hr {
    color: white;
    margin: 0;
    background-color: white;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.grid-title {
    font-size: 22px;
    font-weight: bold;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
    width: 24px !important;
    height: 24px !important;
    // background-position: 0 0;
    // background-size: 18px 18px;
    // padding: 0;
    font-size: 24px !important;
    //     text-align: center;
    line-height: 24px !important;
    //     margin: 0 3px;
}

.dx-datagrid-checkbox-size .dx-checkbox-icon {
    height: 22px !important;
    width: 22px !important;
}

.dx-datagrid-checkbox-size.dx-checkbox-checked .dx-checkbox-icon {
    font-size: 22px !important;
}

.dx-datagrid-group-opened,
.dx-datagrid-group-closed {
    font: 24px/24px DXIcons !important;
    height: 24px !important;
    background-size: 24px 24px !important;
}

.article-container {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 10px;
}

#navbarChat {
    font-size: 40px;
    line-height: 24px;
    color: dodgerblue;
}

.icon-badge[data-count] {
    position: relative;
}

.icon-badge[data-count]:after {
    position: absolute;
    right: 0em;
    top: -0.3em;
    content: attr(data-count);
    padding: 0.4em;
    border-radius: 10em;
    line-height: 0.9em !important;
    color: white;
    background: rgba(255, 0, 0, 1);
    text-align: center;
    min-width: 2em;
    font: bold 0.3em sans-serif;
}

.cookie-dialog {
    position: fixed !important;
    top: initial !important;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: white;
    z-index: 10000;
    font-size: 24px;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid burlywood;
    .btn {
        color: white !important;
        font-size: 22px;
        min-width: 150px;
        margin: 10px;
    }
}

.shadow.bg-white.rounded {
    border: 1px solid lightgray;
}

.profile-image.img-thumbnail {
    padding: 0 !important;
}

.separator {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: -4px !important;
    margin-top: 10px;
    color: gray;
    font-style: italic;
    width: 100%;
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid gray;
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}

// .nav-sidebar>.nav-item {
//     height: 32px;
//     line-height: 16px;
// }
// .dx-texteditor-label .dx-label {
//     color: green !important;
//     font-weight: bold !important;
// }
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    font-weight: bold;
}

.input-group-append {
    height: 37px;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.dx-toast-message {
    font-size: 22px !important;
}

.dx-icon-edit-button-addrow,
.dx-icon-blue {
    color: blue !important;
    font-size: 30px !important;
    // font-weight: bold;
    left: -6px;
    position: relative;
    top: -2px;
}

.dx-icon-xlsxfile {
    color: green !important;
    font-size: 30px !important;
    // font-weight: bold;
    left: -6px;
    position: relative;
    top: -2px;
}
